import React, {useState, useEffect} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

function Predictions(){

    const [predictions, setPredictions] = useState();
    const location = useLocation();
    const navigate = useNavigate();

    async function getPredictions(){
        const response = await axios.get("/api/predictions/" + location.pathname.slice(10,100));
        setPredictions(response.data[0]);
    }

    function redirectToMatch(item){
        navigate("/fixtures/" + item.fixtureId);
    }

    function redirectToLeague(id){
        navigate("/leagues/" + id);
    }

    function convertUTCDateToLocalTime(date) {
        return(new Date(date).toString().slice(4,10));
    }

    function renderComparison() { 
        if(predictions?.comparison === undefined || predictions?.comparison === null) return null;
        else return (
            <div>
                <table className="comparison-table">

                <tr>
                    <th>Team</th>
                    <th>Home</th>
                    <th>Away</th>
                </tr>

                <tr>
                    <td>Attack</td>
                    <td>{predictions?.comparison?.att?.home}</td>
                    <td>{predictions?.comparison?.att?.away}</td>
                </tr>

                <tr>
                    <td>Defense</td>
                    <td>{predictions?.comparison?.def?.home}</td>
                    <td>{predictions?.comparison?.def?.away}</td>
                </tr>

                <tr>
                    <td>Form</td>
                    <td>{predictions?.comparison?.form?.home}</td>
                    <td>{predictions?.comparison?.form?.away}</td>
                </tr>

                <tr>
                    <td>Goal</td>
                    <td>{predictions?.comparison?.goals?.home}</td>
                    <td>{predictions?.comparison?.goals?.away}</td>
                </tr>

                <tr>
                    <td>h2h</td>
                    <td>{predictions?.comparison?.h2h?.home}</td>
                    <td>{predictions?.comparison?.h2h?.away}</td>
                </tr>


                <tr>
                    <td>Total</td>
                    <td>{predictions?.comparison?.total?.home}</td>
                    <td>{predictions?.comparison?.total?.away}</td>
                </tr>

                </table>
        </div>
        )
    }

    useEffect(() => {
        getPredictions();
    }, []);

    if (predictions === undefined || predictions === null) return null;
    
    else {
        return (
            <div>
    
            <br/>
            <div className="text-minor mb10">PREDICTIONS</div>

            {predictions?.predictions?.advice === undefined ? null : <div className="advice">Advice: {predictions?.predictions?.advice}</div> }
            {predictions?.predictions?.under_over === undefined ? null: <div className="under-over">Under Over : {predictions?.predictions?.under_over}</div> }

            

            {predictions?.predictions === undefined? null: 
                
                <div>

                    <div className="head-wrapper">
                        <span className="text-minor">GOALS</span>
                        <span className="text-minor">WIN RATE</span>
                    </div>

                    <div className="win-rates-wrapper">
                        
                        <table className="win-rates">
                            <tr>
                                <th>Home</th>
                                <th>Away</th>
                            </tr>
                            <tr>
                                <td>{predictions?.predictions?.goals?.home}</td>
                                <td>{predictions?.predictions?.goals?.away}</td>
                            </tr>
                        </table>   
                        
                        <table className="win-rates">
                            <tr>
                                <th>1</th>
                                <th>X</th>
                                <th>2</th>
                            </tr>

                            <tr>
                                <td>{predictions?.predictions?.percent?.home}</td>
                                <td>{predictions?.predictions?.percent?.draw}</td>
                                <td>{predictions?.predictions?.percent?.away}</td>
                            </tr>
                        </table>

                    </div>
                </div>

            }


            <br/>

            <div>{renderComparison()}</div>

            <div className="text-minor mb10 mt10">LAST MATCHES</div>

            <div>{predictions?.teams?.home?.league?.form}</div>
            <div>{predictions?.teams?.away?.league?.form}</div>

            <div className="head-to-head">
            {predictions?.h2h.map((item) => (
                <div>
                        <div className="fixtures-block-1" onClick={() => redirectToLeague(item.league.id)}>
                        <div><img className="fixture-league-logo" alt="league-logo" src={item.league.logo}/></div>

                        <span>
                            <div>{item.league.name}</div>
                            <div className="text-minor">{item.league.country}</div> 
                        </span>
                
                        
                        </div>
                        
                        <div className="fixture" onClick={() => redirectToMatch(item)}>

                        <div className="status"><div className="datetime"><div className="predictions-center">
                            <span classname="predictions-year-major">{item.fixture.date.slice(0,4)}</span>
                            <span className="predictions-date-minor">{convertUTCDateToLocalTime(item.fixture.date.slice(0,8))}</span>
                        </div></div></div>


                            <div className="h2h">

                                <div className="teams">

                                    <span><img src={item.teams.home.logo}/></span>
                                    <span> {item.teams.home.name}</span>
                                    

                                </div>

                                <div className="teams">

                                    <span><img src={item.teams.away.logo}/></span>
                                    <span> {item.teams.away.name}</span>
                                    

                                </div>

                            </div>

                            {item?.status?.status !== "SC"? <div className="scores">

                            <div>
                                <div className="score">{item.goals.home}</div>
                            </div>

                            <div>
                                <div className="score">{item.goals.away}</div>
                            </div>

                            </div>: null}

                        </div>

                        </div>
            ))}
            </div>

        </div>
        )
    }
}

export default Predictions;